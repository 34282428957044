import React from "react";

interface CurrentAudienceContextState {
  audience?: string;
}

interface CurrentAudienceContextProviderValue {
  audienceState?: CurrentAudienceContextState;
  setAudienceState?: React.Dispatch<
    React.SetStateAction<CurrentAudienceContextState>
  >;
}

interface CurrentAudienceProviderProps {
  children: JSX.Element;
  // eslint-disable-next-line react/require-default-props
  initialState?: CurrentAudienceContextState;
}

export const CurrentAudienceContext = React.createContext<CurrentAudienceContextProviderValue>(
  {
    audienceState: undefined,
    setAudienceState: undefined,
  }
);

function CurrentAudienceProvider({
  children,
  initialState = { audience: undefined },
}: CurrentAudienceProviderProps): JSX.Element {
  const [audienceState, setAudienceState] = React.useState(initialState);
  const contextValue = React.useMemo(() => {
    return { audienceState, setAudienceState };
  }, [audienceState, setAudienceState]);
  return (
    <CurrentAudienceContext.Provider value={contextValue}>
      {children}
    </CurrentAudienceContext.Provider>
  );
}

export default CurrentAudienceProvider;
