import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import type { InstallationProps } from "./Installation.d";

const useStyles = makeStyles((theme) => ({
  root: {
    // TODO: use palette
    border: "2px #EAEAEA solid",
    borderRadius: theme.spacing(1),
  },
  header: {
    margin: "0 !important",
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px !important`,
    background: theme.palette.background.paper,
    fontSize: "0.875rem",
    fontFamily: ["Overpass", "-apple-system", "sans-serif"].join(","),
    fontWeight: 700,
    lineHeight: 1.43,
    letterSpacing: 0.5,
  },
  content: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: `${theme.spacing(1)}px !important`,
  },
  title: {
    fontWeight: 700,
    textTransform: "uppercase",
    letterSpacing: 0.5,
    position: "relative",
    display: "block",
    flexGrow: 1,
    margin: 0,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    "&:before": {
      content: "''",
      display: "block",
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      width: theme.spacing(1),
      borderRadius: theme.spacing(1),
      backgroundImage: "linear-gradient(to bottom, #f39201, #f9b100)",
    },
  },
  search: {
    zIndex: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: theme.spacing(1),
      "& .MuiFormControl-root": {
        width: "100%",
      },
    },
  },
  skeleton: {
    color: "#FAFAFA",
    "&:before": {
      backgroundColor: "#EAEAEA",
      backgroundImage: "none",
    },
    "&:after": {
      content: "''",
      display: "block",
      position: "absolute",
      width: "200px",
      height: "0.875rem",
      transform: `translateY(-${theme.spacing(3.5)}px)`,
      padding: `${theme.spacing(1.5)}px 0`,
      background: "#FAFAFA",
      borderRadius: theme.spacing(1),
    },
  },
}));

function Empty(props: {
  children: React.ReactNode;
  className: string;
}): JSX.Element {
  const { children, className } = props;
  return <div className={className}>{children}</div>;
}

function Installation(props: InstallationProps): JSX.Element {
  const { children, title, onClick, loading, searchComponent = null } = props;
  const classes = useStyles();
  const Header = onClick ? CardActionArea : Empty;
  return (
    <Card
      variant="outlined"
      component="article"
      className={classes.root}
      data-qa="installation-card"
    >
      <Header
        disabled={!onClick}
        className={classes.header}
        onClick={onClick}
        data-qa="installation-action-header"
      >
        <CardContent className={classes.content} aria-hidden={!!loading}>
          <h2
            className={`${classes.title} ${loading ? classes.skeleton : ""}`}
            data-qa="installation-title"
          >
            {title}
          </h2>
          <div className={classes.search} data-qa="installation-search">
            {searchComponent}
          </div>
        </CardContent>
      </Header>
      {children}
    </Card>
  );
}

export default Installation;
