import React from "react";
import kebabCase from "lodash/kebabCase";
import fill from "lodash/fill";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import type { History } from "history";
import { useQuery } from "react-query";
import { CurrentAudienceContext } from "../../contexts";
import type { ListingViewProps } from "./ListingView.d";
import Installation from "../../components/Installation";
import InstallationComponent from "../../components/InstallationComponent";
import { fetchInstallationsList } from "../../querys";
import type { InstallationListDTO } from "../../querys/querys.d";

enum QUERY_PARAMETER_KEY {
  AUDIENCE = "audience",
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      margin: `${theme.spacing(2)}px 0`,
    },
    "& > *:first-child": {
      marginTop: 0,
    },
    "& > *:last-child": {
      marginBottom: 0,
    },
  },
  status: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
    },
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(
      2
    )}px`,
    backgroundColor: "rgba(216, 216, 216, 0.3)",
  },
}));

export const constructHistoryClick = (history: History) => (
  url: string,
  hash?: string
): void => {
  const detailViewUrl = kebabCase(url);
  const detailViewHash = hash ? `#${kebabCase(hash)}` : "";
  history.push(`${detailViewUrl}${detailViewHash}`);
};

const skeletonListing = fill(Array(2), {
  component: "component",
});

export function ListingView(props: ListingViewProps): JSX.Element {
  const {
    loading,
    listing = skeletonListing,
    onClick = constructHistoryClick,
  } = props;
  const history = useHistory();
  const classes = useStyles();
  const handleClick = onClick(history);
  return (
    <div className={classes.root}>
      {listing.map(({ component: name }, key) => {
        return (
          <Installation
            loading={loading}
            title={name}
            onClick={() => handleClick(name)}
            key={`listing:${name}${loading ? key : ""}`}
          >
            <div className={classes.status} data-qa="listing-view-status">
              <InstallationComponent
                name={name}
                onClick={handleClick}
                loading={loading}
              />
            </div>
          </Installation>
        );
      })}
    </div>
  );
}

function useParameterQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function ListingViewWithData(): JSX.Element {
  const parameter = useParameterQuery();
  const { setAudienceState } = React.useContext(CurrentAudienceContext);
  const audience = parameter.get(QUERY_PARAMETER_KEY.AUDIENCE) ?? undefined;
  const { key, query } = fetchInstallationsList({
    audience,
  });

  const { isLoading, isError, data: results } = useQuery(key, query);
  const [listing, setListing] = React.useState<InstallationListDTO[]>([]);

  React.useEffect(() => {
    if (audience && parameter && setAudienceState) {
      setAudienceState({
        audience,
      });
    }
  }, [parameter, audience, setAudienceState]);

  React.useEffect(() => {
    if (results) setListing(results?.data);
  }, [results]);

  if (isLoading) {
    return <ListingView loading data-qa="listing-view-loading" />;
  }

  if (isError) {
    return <span data-qa="listing-view-error">Error</span>;
  }

  return <ListingView listing={listing} data-qa="listing-view" />;
}

export default ListingViewWithData;
