import React from "react";
import dayjs from "dayjs";
import Fuse from "fuse.js";
import reduce from "lodash/reduce";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { DataGrid } from "@material-ui/data-grid";
import { useQuery } from "react-query";
import type {
  ValueFormatterParams,
  GridSortDirection,
} from "@material-ui/data-grid";
import { useParams, useLocation } from "react-router-dom";
import Installation from "../../components/Installation";
import TableStatus from "../../components/Status/TableStatus";
import StatusState from "../../components/Status/StatusState.enum";
import { fetchComponentsList } from "../../querys";
import type { DetailViewProps, DetailViewRows } from "./DetailView.d";
import { renderGridCellExpand } from "./GridCellExpand";

const useStyles = makeStyles({
  root: {
    height: "60vh",
    width: "100%",
    "& svg": {
      display: "block",
    },
  },
  table: {
    border: "none",
    borderColor: "#EAEAEA",
    borderTop: "2px #EAEAEA solid",
    borderRadius: 0,
    "& .MuiDataGrid-colCellTitle": {
      fontWeight: 700,
    },
    "& .MuiDataGrid-columnSeparator": {
      visibility: "hidden",
    },
    "& .MuiTablePagination-caption:after": {
      content: "' rows'",
    },
  },
});

export const rawColumns = [
  {
    field: "component",
    headerName: "Name",
    width: 23 * 8,
    renderCell: renderGridCellExpand,
  },
  {
    field: "running",
    headerName: "Status",
    width: 14 * 8,
    renderCell: TableStatus,
  },
  {
    field: "message",
    headerName: "Message",
    flex: 1,
    renderCell: renderGridCellExpand,
  },
  {
    field: "updated",
    headerName: "Last update",
    width: 23 * 8,
    valueFormatter: (params: ValueFormatterParams): string =>
      `${dayjs(params.value as Date).format("D MMMM, HH:mm:ss")} (GMT)`,
  },
];

const sortModel = [
  {
    field: "updated",
    sort: "desc" as GridSortDirection,
  },
];

const options = {
  isCaseSensitive: false,
  shouldSort: false,
  threshold: 0.6,
  keys: ["component", "status", "environment", "message", "region"],
  includeScore: true,
  useExtendedSearch: true,
};

function adjustColumnWidths(cols: typeof rawColumns, rows: DetailViewRows[]) {
  const characterSpacing = 10;
  let componentMaxCharacterLength = 0;

  if (rows.length > 0) {
    componentMaxCharacterLength =
      reduce(rows, (acc: any, item) => {
        if (typeof acc !== "number") return 0;
        return item.component.length > acc ? item.component.length : acc;
      }) * characterSpacing;
  }
  const colscopy = [...cols];
  colscopy[0].width = componentMaxCharacterLength;

  return colscopy;
}
const extendedSearch = "'";

export function DetailView(props: DetailViewProps): JSX.Element {
  const { name, rows, loading } = props;
  const location = useLocation();
  const classes = useStyles();
  const [searchValue, setSearchValue] = React.useState("");
  const [results, setResults] = React.useState(rows);
  const [columns, setColumns] = React.useState(
    adjustColumnWidths(rawColumns, rows)
  );
  const fuse = React.useRef<
    Fuse<{
      component: string;
      running: boolean;
      status: string;
      updated: Date;
      message: string[];
    }>
  >();

  React.useEffect(() => {
    setSearchValue(location.hash.slice(1));
  }, [location.hash]);

  React.useEffect(() => {
    fuse.current = new Fuse(rows, options);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  React.useEffect(() => {
    if (fuse.current && searchValue.length > 0) {
      setColumns(adjustColumnWidths(rawColumns, rows));
      setResults(
        fuse.current
          .search(`${extendedSearch}${searchValue}`)
          .map((value) => value.item)
      );
    } else {
      setColumns(adjustColumnWidths(rawColumns, rows));
      setResults(rows);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, rows]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.currentTarget.value);
  };

  return (
    <>
      <Installation
        title={name}
        loading={loading}
        searchComponent={
          <form role="search">
            <TextField
              label="Search"
              value={searchValue}
              inputProps={{ "data-qa": "search" }}
              onChange={handleOnChange}
            />
          </form>
        }
      >
        <div className={classes.root}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }} data-qa="data-grid">
              <DataGrid
                className={classes.table}
                rows={results}
                columns={columns}
                sortModel={sortModel}
                rowHeight={8 * 8}
                autoPageSize
                disableColumnMenu
              />
            </div>
          </div>
        </div>
      </Installation>
    </>
  );
}

export function DetailViewWithData(): JSX.Element {
  const { key, query } = fetchComponentsList();
  const { installationId } = useParams<{ installationId: string }>();
  const { isLoading, isError, data: installation } = useQuery(
    [key, { name: installationId }],
    query
  );

  if (isLoading) {
    return (
      <DetailView name="name" rows={[]} loading data-qa="detail-view-loading" />
    );
  }

  if (isError || !installation) {
    return <span data-qa="detail-view-error">Error</span>;
  }

  const { components, timestamp } = installation.data;
  const transformedRows = components.map((row) => ({
    ...row,
    id: `${row.environment}:${row.installation}:${row.component}`,
    status: row.running ? StatusState.Available : StatusState.Stopped,
    updated: new Date(timestamp),
  }));

  return (
    <DetailView
      name={installationId}
      rows={transformedRows}
      data-qa="detail-view"
    />
  );
}

export default DetailViewWithData;
