import React from "react";

function NotFoundView(): JSX.Element {
  return (
    <>
      <p data-qa="not-found-view-code">404</p>
    </>
  );
}

export default NotFoundView;
