import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { DetailView, ListingView, NotFoundView } from "..";
import CurrentAudienceProvider from "../../contexts";
import { BaseLayout } from "../../layout";
import "./App.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#F18701",
      contrastText: "#0D1A26",
    },
    error: {
      main: "#EE4848",
    },
    success: {
      main: "#33D848",
    },
  },
  typography: {
    fontFamily: ["Overpass", "-apple-system", "sans-serif"].join(","),
  },
});

const queryClient = new QueryClient();

export function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        {/* NOTE: automatically not included in production bundle */}
        <ReactQueryDevtools initialIsOpen={false} />
        <CurrentAudienceProvider>
          <div className="App">
            <CssBaseline />
            <BaseLayout>
              <Switch>
                <Route path="/:installationId" exact>
                  <DetailView />
                </Route>
                <Route path="/" exact>
                  <ListingView />
                </Route>
                <Route>
                  <NotFoundView />
                </Route>
              </Switch>
            </BaseLayout>
          </div>
        </CurrentAudienceProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export function AppWithRouter(): JSX.Element {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default AppWithRouter;
