import React from "react";
import noop from "lodash/noop";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import State from "../StatusState.enum";
import { PillStatusProps } from "./PillStatus.d";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    padding: `
      ${theme.spacing(1)}px
      ${theme.spacing(2)}px 
      ${theme.spacing(1)}px 
      ${theme.spacing(4.1)}px
    `,
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.background.paper,
    "&:after": {
      content: "''",
      display: "block",
      position: "absolute",
      top: "50%",
      left: theme.spacing(1.5),
      transform: "translate(50%, -50%)",
      width: theme.spacing(1),
      height: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
    },
  },
  available: {
    color: theme.palette.success.dark,
    "&:after": {
      backgroundImage: `linear-gradient(to bottom, ${theme.palette.success.light} 0%, ${theme.palette.success.dark} 250%)`,
    },
  },
  stopped: {
    color: theme.palette.error.dark,
    "&:after": {
      backgroundImage: `linear-gradient(to bottom, ${theme.palette.error.main} 0%, ${theme.palette.error.dark} 250%)`,
    },
  },
  skeleton: {
    color: "white !important",
    backgroundColor: "white !important",
    "&:after": {
      display: "none",
    },
  },
}));

function Status(props: PillStatusProps): JSX.Element {
  const { name, state, onClick = noop, loading = false } = props;
  const classes = useStyles();
  const statusClass =
    state === State.Available ? classes.available : classes.stopped;
  const loadingClass = loading ? classes.skeleton : "";
  return (
    <Button
      data-qa="status-button"
      onClick={onClick}
      variant="contained"
      disableElevation
      className={`${classes.root} ${statusClass} ${loadingClass}`}
      aria-hidden={!!loading}
      disabled={!!loading}
    >
      {name}
    </Button>
  );
}

export default Status;
