import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../components/Header";

interface Base {
  children: React.ReactNode;
}

const useStyles = makeStyles((theme) => {
  const radius = theme.spacing(1);
  return {
    root: {
      padding: `
      ${theme.typography.pxToRem(theme.spacing(2))} 
      ${theme.typography.pxToRem(theme.spacing(3))}
      `,
      gridColumnStart: 2,
      gridRowStart: 2,
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(5),
        borderRadius: radius,
        border: `${radius}px ${theme.palette.background.paper} solid`,
        boxShadow: `0px 0px 0px ${radius}px #F5F6F7`,
      },
    },
    main: {
      padding: `${theme.typography.pxToRem(theme.spacing(2))} 0`,
      [theme.breakpoints.up("md")]: {
        padding: `${theme.typography.pxToRem(theme.spacing(4))} 0`,
      },
    },
  };
});

function Base(props: Base): JSX.Element {
  const { children } = props;
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Header />
      <main className={classes.main}>{children}</main>
    </section>
  );
}

export default Base;
