import type { History } from "history";
import React from "react";
import Button from "@material-ui/core/Button";
import { Route, useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { ImmenseMark } from "../../assets/logos";
import { CurrentAudienceContext } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& h1": {
      margin: 0,
      padding: `0 ${theme.typography.pxToRem(theme.spacing(2))}`,
      fontWeight: 900,
      fontSize: theme.typography.pxToRem(36),
      letterSpacing: -1,
      whiteSpace: "nowrap",
    },
  },
  mark: {
    display: "flex",
    transformOrigin: "left center",
    transform: "scale(0.8, 0.8)",
    [theme.breakpoints.up("md")]: {
      transform: "scale(1, 1)",
    },
    "& svg": {
      width: 46,
      height: 56,
    },
  },
  button: {
    flexGrow: 1,
    margin: `${theme.typography.pxToRem(theme.spacing(1))} 0`,
    borderColor: "#eaeaea",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      right: 0,
      bottom: 4,
    },
  },
}));

function redirectToRoot(history: History, audience?: string): void {
  const rootUrl = audience ? `/?audience=${audience}` : "/";
  history.push(rootUrl);
}

function Header(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { audienceState } = React.useContext(CurrentAudienceContext);
  return (
    <header className={classes.root} data-qa="header">
      <div className={classes.mark} data-qa="header-logo">
        <Link to="/">
          <ImmenseMark />
        </Link>
        <h1 data-qa="header-product-title">{t("product.title")}</h1>
      </div>
      <Route path="/:installationId">
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          disableElevation
          onClick={() => redirectToRoot(history, audienceState?.audience)}
          data-qa="header-back-button"
        >
          <strong>{t("dashboard.ui.back")}</strong>
        </Button>
      </Route>
    </header>
  );
}

export default Header;
