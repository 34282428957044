import React from "react";
import ReactDOM from "react-dom";
import "./i18n";
import "./index.css";
import App from "./views/App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line
  reportWebVitals(console.log);
}
