import React from "react";
import fill from "lodash/fill";
import random from "lodash/random";
import { useQuery } from "react-query";
import Status, { State } from "../Status";
import { fetchComponentsList } from "../../querys";
import type {
  InstallationComponentProps,
  InstallationComponentWithDataProps,
} from "./InstallationComponent.d";

const skeletonComponents = fill(Array(5), "").map(() => ({
  message: ["message"],
  component: Math.random().toString(36).substring(1, random(3, 200)),
  running: false,
}));

export function InstallationComponent(
  props: InstallationComponentProps
): JSX.Element {
  const { loading, components = skeletonComponents, onClick, name } = props;
  const renderInstallationComponents = components.map((item, index) => {
    const state = item.running ? State.Available : State.Stopped;
    return (
      <Status
        data-qa="installation-component-status"
        name={item.component}
        state={state}
        onClick={() => onClick(name, item.component)}
        // eslint-disable-next-line react/no-array-index-key
        key={`${name}:${item.component}:${state}:${index}`}
        loading={loading}
      />
    );
  });
  return <>{renderInstallationComponents}</>;
}

export function InstallationComponentWithData(
  props: InstallationComponentWithDataProps
): JSX.Element {
  const { loading } = props;
  const { key, query } = fetchComponentsList();
  const { isLoading, isError, data: installation } = useQuery(
    [key, props],
    query,
    {
      enabled: !loading,
    }
  );

  if (isLoading || loading) {
    return (
      <InstallationComponent
        {...props}
        data-qa="installation-component-loading"
        loading
      />
    );
  }

  if (isError || !installation) {
    return <span data-qa="installation-component-error">Error</span>;
  }

  return (
    <InstallationComponent
      {...props}
      data-qa="installation-component"
      components={installation.data.components}
    />
  );
}

export default InstallationComponentWithData;
