import React from "react";

const ImmenseMark = (): JSX.Element => (
  <svg
    height="56"
    viewBox="0 0 46 56"
    width="46"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="m12.033 15.867-.001 23.158v14.455l.002.508c0 .375.063.735.17 1.078l-9.974-5.847c-.799-.469-1.29-1.335-1.29-2.272v-23.062c0-.937.492-1.802 1.291-2.271l1.283-.753 8.52-4.994zm-5.461-15.867c3.629 0 6.57 2.925 6.57 6.534 0 3.608-2.941 6.533-6.57 6.533-3.63 0-6.572-2.925-6.572-6.533 0-3.609 2.942-6.534 6.572-6.534z"
        fill="#e15d29"
      />
      <path
        d="m24.757 10.617 19.636 11.409c.416.32.668.798.668 1.335 0 .615-.318 1.166-.852 1.474l-8.714 5.032-21.413-12.425v-2.173l8.056-4.652c.81-.467 1.809-.467 2.619 0z"
        fill="#efa92c"
      />
      <path
        d="m45.17 25.88-7.815 4.575.013.007-23.286 13.632v10.179c0 .624.318 1.183.851 1.495.52.305 1.139.307 1.663.017l28.095-16.445c.81-.475 1.309-1.35 1.309-2.3v-11.84c-.238.262-.513.494-.83.68"
        fill="#e9822c"
      />
    </g>
  </svg>
);

export default ImmenseMark;
