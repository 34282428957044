import React from "react";
import upperFirst from "lodash/upperFirst";
import type { GridCellParams } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import StatusState from "../StatusState.enum";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    padding: `
      ${theme.spacing(1)}px
      ${theme.spacing(0)}px 
      ${theme.spacing(1)}px 
      ${theme.spacing(2.5)}px
    `,
    "&:after": {
      content: "''",
      display: "block",
      position: "absolute",
      top: "50%",
      left: 0,
      transform: "translate(50%, -50%)",
      width: theme.spacing(1),
      height: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
    },
  },
  available: {
    color: theme.palette.success.dark,
    "&:after": {
      backgroundImage: `linear-gradient(to bottom, ${theme.palette.success.light} 0%, ${theme.palette.success.dark} 250%)`,
    },
  },
  stopped: {
    color: theme.palette.error.dark,
    "&:after": {
      backgroundImage: `linear-gradient(to bottom, ${theme.palette.error.main} 0%, ${theme.palette.error.dark} 250%)`,
    },
  },
}));

function TableStatus(params: GridCellParams): JSX.Element {
  const classes = useStyles();
  const { value } = params;
  const statusClass = value ? classes.available : classes.stopped;
  const statusLabel = value
    ? StatusState.Available.toLocaleLowerCase()
    : StatusState.Stopped.toLocaleLowerCase();
  return (
    <span className={`${classes.root} ${statusClass}`} data-qa="table-status">
      {upperFirst(statusLabel)}
    </span>
  );
}

export default TableStatus;
