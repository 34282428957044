import type { InstallationListData, ComponentListData } from "./querys.d";
import QUERY_KEYS from "./keys";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchInstallationsList = ({
  audience = "platform",
}: {
  audience?: string;
}) => {
  return {
    key: QUERY_KEYS.INSTALLATIONS,
    query: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/health?audience=${audience}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return (response.json() as unknown) as InstallationListData;
    },
  };
};

interface FetchComponentsListQueryKey {
  queryKey: (QUERY_KEYS | any)[];
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchComponentsList = () => {
  return {
    key: QUERY_KEYS.COMPONENTS,
    query: async ({ queryKey }: FetchComponentsListQueryKey) => {
      const [, { name }] = queryKey;
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/health/components/${name}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return (response.json() as unknown) as ComponentListData;
    },
  };
};
